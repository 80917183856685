import { ReactElement } from "react";

export const HideIcon = (): ReactElement => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute right-4 top-4 "
    >
      <path
        d="M5.50705 7.57787C5.12371 7.1952 4.89038 6.6752 4.89038 6.09187C4.89038 4.9232 5.83171 3.9812 6.99971 3.9812C7.57771 3.9812 8.10971 4.2152 8.48638 4.59787"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06987 6.46582C8.91521 7.32582 8.23787 8.00449 7.37854 8.16049"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.4364 9.64823C2.3784 8.81757 1.4824 7.60423 0.833069 6.09157C1.48907 4.57223 2.39107 3.35223 3.45574 2.5149C4.51374 1.67757 5.7344 1.2229 6.99974 1.2229C8.2724 1.2229 9.4924 1.68423 10.5571 2.52757"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.965 3.9939C12.4237 4.60323 12.827 5.30656 13.1664 6.09123C11.855 9.12923 9.53771 10.9592 6.99971 10.9592C6.42437 10.9592 5.85704 10.8659 5.31171 10.6839"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.258 0.833008L1.742 11.349"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
