import { Layout } from "./components/layout/Layout";
import { AppProvider } from "./components/providers/AppProvider";

function App() {
  return (
    <Layout>
      <AppProvider />
    </Layout>
  );
}

export default App;
