import { ChangeEvent, ReactElement, useState } from "react";
import { logoFull } from "../../assets";
import { HideIcon } from "../../components/icons/HideIcon";
import { useMutation } from "@tanstack/react-query";
import { signIn } from "../../services/auth/auth.service";
import { MutationKeys } from "../../enums/react-query";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes } from "../../types/Routes";
import { SignInRequest } from "../../services/auth/sign-in-request.dto";

export const SignIn = (): ReactElement => {
  const navigate = useNavigate();
  const [signInreq, setSignInReq] = useState<SignInRequest>({
    email: "",
    password: "",
  });

  const { mutate: signInMutate, isPending } = useMutation({
    mutationKey: [MutationKeys.SIGNIN],
    mutationFn: () => signIn(signInreq.email, signInreq.password),
    onSuccess() {
      navigate(ProtectedRoutes.BASE);
    },
  });

  const isBtnDisabled = isPending || !signInreq.email || !signInreq.password;

  const signInFormHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSignInReq((prev) => ({ ...prev, [name]: value }));
  };

  const signInHandler = () => {
    if (isBtnDisabled) return;
    signInMutate();
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-bgLight">
      <img src={logoFull} alt="Eazipower Logo" className="mb-5 h-121px" />
      <div className="flex flex-col gap-5 rounded-lg p-7 bg-bgWhite w-353px">
        <p className="text-xl font-semibold leading-7 text-textDark">
          Eazipower Admin
        </p>
        <input
          name="email"
          value={signInreq.email}
          onChange={signInFormHandler}
          type="email"
          placeholder="Email"
          className="block w-full p-4 text-sm leading-4 border rounded-lg outline-none border-gray300 h-42px bg-gray50 placeholder:text-gray400 placeholder:text-sm text-textDark"
        />
        <div className="relative">
          <input
            name="password"
            value={signInreq.password}
            onChange={signInFormHandler}
            type="password"
            placeholder="Password"
            className="block w-full p-4 pr-8 text-sm leading-4 border rounded-lg outline-none text-textDark border-gray300 h-42px bg-gray50 placeholder:text-gray400 placeholder:text-sm"
          />
          <HideIcon />
        </div>
        <button
          className={`w-full h-10 rounded-lg bg-main text-bgWhite ${
            isBtnDisabled
              ? "opacity-50 cursor-not-allowed"
              : "opacity-1 cursor-pointer"
          }`}
          onClick={signInHandler}
        >
          Sign in
        </button>
      </div>
    </div>
  );
};
