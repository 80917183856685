import { ReactElement } from "react";
import { ProtectedRoutes, PublicRoutes } from "../types/Routes";
import { Navigate, Outlet } from "react-router-dom";
import { SignIn } from "../pages/auth/SignIn";
import { AccessToken } from "../enums/access-token";

const AuthRouter = (): ReactElement => {
  const isAuth = localStorage.getItem(AccessToken.KEY);
  return isAuth ? <Navigate to={ProtectedRoutes.BASE} /> : <Outlet />;
};

export const publicRoutes = [
  {
    path: PublicRoutes.AUTH,
    element: <AuthRouter />,
    children: [
      { path: PublicRoutes.SIGNIN, name: "SignIn", element: <SignIn /> },
    ],
  },
];
