import { ReactElement } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "react-hot-toast";
import { queryClient } from "../../utils/react-query";
import AppRouter from "../../routes/AppRouter";
import { toasterConfig } from "../../config/react-hot-toast";

export const AppProvider = (): ReactElement => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Toaster {...toasterConfig} />
        <AppRouter />
      </QueryClientProvider>
    </>
  );
};
