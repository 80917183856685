export enum ProtectedRoutes {
  BASE = "/",
  DASHBOARD = "/",
  CUSTOMERS = "/customers",
  PAYMENT_PLANS = "/payment-plans",
  TRANSACTIONS = "/transactions",
  DEVICES = "/devices",
  SETTINGS = "/settings",
  PROFILE = "/settings/profile",
  MESSAGES = "/settings/messages",
  KYC = "/settings/kyc",
  NOT_FOUND = "*",
}

export enum PublicRoutes {
  AUTH = "/auth",
  SIGNIN = "/auth/signin",
}
