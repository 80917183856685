import { request } from "graphql-request";
import { SIGN_IN_MUTATION } from "./auth.queries";
import { SignInResponse } from "./sign-in-response.dto";

export const signIn = async (
  email: string,
  password: string
): Promise<SignInResponse> =>
  request(process.env.REACT_APP_BACKEND_API!, SIGN_IN_MUTATION, {
    email,
    password,
  });
