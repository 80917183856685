import {
  DefaultOptions,
  MutationCache,
  QueryCache,
  QueryClient,
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { AccessToken } from "../enums/access-token";
import { SignInResponse } from "../services/auth/sign-in-response.dto";
import { DefaultResponse } from "../types/default-response";
import { MutationKeys } from "../enums/react-query";
import { ToastStatus } from "../enums/react-hot-toast";
import { errorParser } from "./error-parser";

const defaultOptions: DefaultOptions = {
  queries: {
    staleTime: 0,
    retry: false,
  },
};

export const queryClient = new QueryClient({
  defaultOptions,
  mutationCache: new MutationCache({
    onError(err: unknown) {
      const errorMsg = errorParser(err);
      toast.custom(<div>{errorMsg}</div>); //TODO: create a custom toast for error
    },
    onSettled(data, _, __, ___, mutation) {
      const isSignInMutate =
        mutation.options.mutationKey &&
        mutation.options.mutationKey[0] === MutationKeys.SIGNIN;

      if (isSignInMutate) {
        const { accessToken } = data as SignInResponse;
        localStorage.setItem(AccessToken.KEY, accessToken.token);
      }

      const { alert } = data as DefaultResponse;
      if (alert && alert.status === ToastStatus.SUCCESS) {
        toast.success(alert.message); //TODO: create a custom toast for success
      } else if (alert && alert.status === ToastStatus.ERROR) {
        toast.error(alert.message);
      }
    },
  }),
  queryCache: new QueryCache({
    onError(err) {
      const errorMsg = errorParser(err);
      toast.error(errorMsg);
    },
  }),
});
