import { AccessToken } from "../enums/access-token";
import { PublicRoutes } from "../types/Routes";
import { GraphQLErrorResponse } from "../types/graphql-error";

export const errorParser = (err: unknown): string => {
  const errorMessage = (err as { message: string }).message;
  // Extract the JSON part of the error message
  const jsonStartIndex = errorMessage.indexOf("{");
  const jsonPart = errorMessage.substring(jsonStartIndex);

  const parsedError = JSON.parse(jsonPart) as GraphQLErrorResponse;
  const errMsg =
    parsedError.response?.errors[0].message ?? "Something went wrong!";

  if (errMsg === "Unauthorized") {
    setTimeout(() => {
      localStorage.removeItem(AccessToken.KEY);
      window.location.href = PublicRoutes.SIGNIN;
    }, 2000);
  }

  return errMsg;
};
