import { ToasterProps } from "react-hot-toast";

export const toasterConfig: ToasterProps = {
  position: "top-right",
  toastOptions: {
    className: "",
    style: { borderRadius: "4px", fontSize: "14px" },
    success: {
      iconTheme: {
        primary: "#00ac69",
        secondary: "#fff",
      },
      icon: "",
      style: {
        borderLeft: "8px solid",
        borderColor: "#00ac69",
      },
    },
    error: {
      iconTheme: {
        primary: "#fd4a49",
        secondary: "#fff",
      },
      icon: "X",
      style: {
        borderLeft: "8px solid",
        borderColor: "#fd4a49",
      },
    },
  },
};
