import React, { ReactElement, Suspense } from "react";
import { PageLoading } from "../components/atoms/PageLoading";
import { Navigate, Outlet } from "react-router-dom";
import { ProtectedRoutes, PublicRoutes } from "../types/Routes";
import { AccessToken } from "../enums/access-token";

// pages
const NotFound = React.lazy(() => import("../pages/in-app/NotFound"));
const Dashboard = React.lazy(() => import("../pages/in-app/Dashboard"));
const Customers = React.lazy(() => import("../pages/in-app/Customers"));
const PaymentPlans = React.lazy(() => import("../pages/in-app/PaymentPlans"));
const Transactions = React.lazy(() => import("../pages/in-app/Transactions"));
const Devices = React.lazy(() => import("../pages/in-app/Devices"));
const Profile = React.lazy(() => import("../pages/in-app/Profile"));
const Messages = React.lazy(() => import("../pages/in-app/Messages"));
const Kyc = React.lazy(() => import("../pages/in-app/Kyc"));

const InAppRouter = (): ReactElement => {
  const isAuth = localStorage.getItem(AccessToken.KEY);

  return (
    <Suspense fallback={<PageLoading />}>
      {isAuth ? (
        <div>
          <Outlet />
        </div>
      ) : (
        <Navigate to={PublicRoutes.SIGNIN} />
      )}
    </Suspense>
  );
};

const SettingsRouter = (): ReactElement => {
  return <Outlet />;
};

export const protectedRoutes = [
  {
    path: ProtectedRoutes.BASE,
    element: <InAppRouter />,
    children: [
      {
        path: ProtectedRoutes.DASHBOARD,
        name: "Dashboard",
        element: <Dashboard />,
      },
      {
        path: ProtectedRoutes.CUSTOMERS,
        name: "Customers",
        element: <Customers />,
      },
      {
        path: ProtectedRoutes.PAYMENT_PLANS,
        name: "Payment Plans",
        element: <PaymentPlans />,
      },
      {
        path: ProtectedRoutes.TRANSACTIONS,
        name: "Transactions",
        element: <Transactions />,
      },
      {
        path: ProtectedRoutes.DEVICES,
        name: "Devices",
        element: <Devices />,
      },
      {
        path: ProtectedRoutes.SETTINGS,
        name: "Settings",
        element: <SettingsRouter />,
        children: [
          {
            path: "",
            name: "Profile",
            element: <Profile />,
          },
          {
            path: ProtectedRoutes.MESSAGES,
            name: "Messages",
            element: <Messages />,
          },
          {
            path: ProtectedRoutes.KYC,
            name: "Kyc",
            element: <Kyc />,
          },
        ],
      },
      {
        path: ProtectedRoutes.NOT_FOUND,
        name: "404",
        element: <NotFound />,
      },
    ],
  },
];
